import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { postMultipartWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { AppContext } from "../context/user/AppContext";
import Loader from "./Loader";
import ic_options_menu from "../assets/images/ic_options_menu.svg";
import ic_document from "../assets/images/document_image.png";

const GoodsDetails = () => {
  const data = useLocation().state;
  const [vehicleRequest, setVehicleRequest] = useState({ ...data });
  const { customerData, mainClass } = useContext(AppContext);

  const [goodDetails, setGoodDetails] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (vehicleRequest) setLoading(true);
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.GOODS_DETAILS,
      JSON.stringify({ ...customerData, load_id: vehicleRequest.trip_id ? vehicleRequest.trip_id : vehicleRequest.trip_vehicle_trip_id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        setGoodDetails(res.json.load_details);
      })
      .catch((err) => console.log(err));

    return () => {};
  }, []);
  return (
    <>
      <main
        className={
          "main-section-GoodsDetails main-section-VehicleDetails " + mainClass
        }
      >
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="main-body-GoodsDetails">
            {/* From details section */}
            <section className="from-details">
              <div className="common-body mt-4 p-3">
                <div className="row">
                  <div className="top-content ">
                    <h5 className="card-heading">Delivery Date</h5>
                    <hr />
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <label htmlFor="">Trip Delivery Date</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_delivery_date}
                    </p>
                  </div>
                  {/* <div className="col-lg-3 col-md-6">
                    <label htmlFor="">Estimated Start Time</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_start_time}
                    </p>
                  </div> */}
                  
                </div>
              </div>
            </section>
            <section className="from-details">
              <div className="common-body mt-4 p-3">
                <div className="row">
                  <div className="top-content ">
                    <h5 className="card-heading">From</h5>
                    <hr />
                  </div>
                  {/* <div className="col-lg-3 col-md-6 ">
                    <label htmlFor="">Estimated Start Date</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_start_date}
                    </p>
                  </div> */}
                  {/* <div className="col-lg-3 col-md-6">
                    <label htmlFor="">Estimated Start Time</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_start_time}
                    </p>
                  </div> */}
                  <div className="col-lg-3 col-md-6">
                    <label htmlFor="">Country</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_start_country}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <label htmlFor="">City</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_start_city}
                    </p>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">Address</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_start_address}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* To details section */}
            <section className="to-details">
              <div className="common-body mt-4 p-3">
                <div className="row">
                  <div className="top-content ">
                    <h5 className="card-heading">To</h5>
                    <hr />
                  </div>
                  {/* <div className="col-lg-3 col-md-6">
                    <label htmlFor="">Estimated End Date</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_end_date}
                    </p>
                  </div> */}
                  {/* <div className="col-lg-3 col-md-6">
                    <label htmlFor="">Estimated End Time</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_end_time}
                    </p>
                  </div> */}
                  <div className="col-lg-3 col-md-6">
                    <label htmlFor="">Country</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_end_country}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <label htmlFor="">City</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_end_city}
                    </p>
                  </div>
                  <div className="col-3">
                    <label htmlFor="">Address</label>
                    <p className="data">
                      {goodDetails && goodDetails.trip_end_address}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* Goods details */}
            <section className="goods-details">
              <div className="common-body mt-4 p-3">
                <div className="row">
                  <div className="top-content ">
                    <h5 className="card-heading">Goods Details</h5>
                    <hr />
                  </div>
                  {/* left section */}
                  <div className="col-lg-6">
                    <div className="image">
                      <div
                        id="carouselExampleControls"
                        className="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-inner">
                          {goodDetails &&
                            goodDetails.trip_images &&
                            goodDetails.trip_images.length > 0 &&
                            goodDetails.trip_images.map((image, index) => {
                              return index === 0 ? (
                                <div className="carousel-item active">
                                  <img
                                    src={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      image.trip_image_url
                                    }
                                    className="d-block "
                                    alt={"..."}
                                  />
                                </div>
                              ) : (
                                <div className="carousel-item ">
                                  <img
                                    src={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      image.trip_image_url
                                    }
                                    className="d-block "
                                    alt={"..."}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleControls"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleControls"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* right section */}
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-4 mt-4">
                        <label htmlFor="">Company Name</label>
                        <p className="data">
                          {goodDetails && goodDetails.trip_company_name}
                        </p>
                      </div>
                      <div className="col-lg-4 mt-4">
                        <label htmlFor="">Cargo Type</label>
                          <p className="data">{goodDetails && goodDetails.cargo_type === "Other (Specify)" ? goodDetails.trip_other_cargo_type : goodDetails.cargo_type}</p>
                      </div>
                      {goodDetails.cargo_type === "Container" && (
                        <div className="col-lg-4 mt-4">
                          <label htmlFor="">Container Type</label>
                          <p className="data">
                            {goodDetails && goodDetails.container_type}
                          </p>
                        </div>
                      )}
                  {goodDetails.container_type_name && 
                      <div className="col-lg-4 mt-4">
                          <label htmlFor="">Container Type</label>
                                <p className="data">{goodDetails && goodDetails.container_type_name ? 
                                     goodDetails.container_type_name : "No Container"}</p>
                  </div>}
                  <div className="col-lg-4 mt-4">
                      <label htmlFor="">Quantity</label>
                          <p className="data">{goodDetails && goodDetails.cargo_type === "Container" ? 
                              goodDetails.trip_container_quantity + " Containers" : 
                              goodDetails.cargo_type === "Vehicle" ? goodDetails.quantity + " Vehicles" : 
                              (goodDetails.cargo_type === "Bulk" || goodDetails.cargo_type === "Break bulk"
                                || goodDetails.cargo_type === "Merchandise" || goodDetails.cargo_type === "House Hold Item"
                                || goodDetails.cargo_type === "Building Materials" || goodDetails.cargo_type === "Agricultural Materials"
                                || goodDetails.cargo_type === "Other (Specify)"
                              ) ?
                              goodDetails.quantity + " Quintals" : " - "}
                          </p>
                  </div>
                      
                      {/* <div className="col-lg-4 mt-4">
                        <label htmlFor="">Expected Arrival Time</label>
                        <p className="data">
                          {goodDetails && goodDetails.trip_end_time}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Document Details */}
            
          </div>
        )}
      </main>
    </>
  );
};

export default GoodsDetails;
