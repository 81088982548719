import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import { PostCallWithErrorResponse } from "../api/ApiServices";
// import "../assets/css/login.scss";
import logo from "../assets/images/Login/logo.svg";
import swal from "sweetalert";
import LoadingBar from "react-top-loading-bar";
import { AppContext } from "../context/user/AppContext";
import { Link } from "react-router-dom";
const Login = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    device_token: "",
    device_id: "",
    app_version: "",
    app_type: "delivery",
    device_os: "web",
    username: "",
    password: "",
  });
  const [progress, setProgress] = useState(0);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordLoadingMessage, setPasswordLoadingMessage] = useState(false);
  const [errMsg, setErrMsg] = useState({ username: "", password: "" });
  const { setCustomerData, setUserDetails } = useContext(AppContext);
  const handleSubmit = (e) => {
    setProgress(50);
    e.preventDefault();
    if (state.username === "" || state.password === "") {
      if (state.username === "") {
        setErrMsg({ ...errMsg, username: "Enter Email ID " });
        return;
      }
      if (state.password === "") {
        setErrMsg({ ...errMsg, password: "Enter Password " });
        return;
      }
    } else {
    if (!isPasswordValid) {
      swal({
        icon: "error",
        title: "Invalid password format",
        text: " Password must be at least 8 characters long, contain a lowercase letter,an uppercase letter, and a number or special character.",
      });
    } else {
      PostCallWithErrorResponse(ApiConfig.TRANSPORTER_LOGIN_API, state)
        .then((data) => {
          if (data.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          if (data.json.result) {
            setProgress(100);
            setCustomerData({
              customer_id: data.json.customer_id,
              user_id: data.json.user_id,
              api_key: data.json.api_key,
            });
            localStorage.setItem("api_key", data.json.api_key);
            localStorage.setItem("customer_id", data.json.customer_id);
            localStorage.setItem("user_id", data.json.user_id);
            localStorage.setItem("logedIn", true);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(data.json.user_details)
            );
            setUserDetails(data.json.user_details);
            setLoggedIn(true);
            navigate("/Dashboard");
          } else {
            setProgress(100);
            swal({
              title: "error",
              text: data.json.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch((error) => {
          navigate("/ServerError");
        });
    }
  }
  };

  useEffect(()=>{
    setPasswordLoadingMessage(false); 
  }, []);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/;
    if (passwordRegex.test(password)) {
      setPasswordLoadingMessage(false)
      setIsPasswordValid(true);
    } else {
      setPasswordLoadingMessage(true)
      setIsPasswordValid(false);
    }
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    const newPassword = event.target.value;
    setErrMsg({ ...errMsg, password: "" });
    setState({
      ...state,
      password: newPassword,
    });
    validatePassword(newPassword);
  };

  return (
    <section className=" container-fluid login-main-section ">
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="row">
        <div className="col-lg-6 col-md-6 left-section">
          <div className="left-content">
            <img src={logo} alt="" />
            <h3 className="mt-3 heading">Abay Logistics plc.</h3>
            <p className="left-login-paragraph">
              Welcome to abay Logistitic Transporter Platform, If you have any
              issue during registration/login feel free to contact us +251 945
              434343 or email us{" "}
              <a
                className="text-decoration-none text-light"
                href="mailto: info@abaylogistics.com"
              >
                info@abaylogistics.com
              </a>
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 right-section">
          <div className="right-content ">
            <h3>Login</h3>

            <p className="welcome-login">
              Welcome to <span>Abay Logistics</span>
            </p>
            <form
              action=""
              onSubmit={(e) => handleSubmit(e)}
              autoComplete="off"
            >
              <div className="mb-3 row">
                <label
                  htmlFor="inputEmail"
                  className="col-sm-12 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-12">
                  <input
                    value={state.username}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, username: "" });
                      setState({ ...state, username: e.target.value });
                    }
                    }
                    type="email"
                    className="form-control"
                    id="inputEmail"
                  />
                </div>
                {errMsg.username.length > 0 && (
                    <span className="text-danger">{errMsg.username}</span>
                  )}
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="inputPassword"
                  className="col-sm-12 col-form-label"
                >
                  Password
                </label>
                <div className="col-sm-12">
                  <input
                    type="password"
                    value={state.password}
                    // onChange={(e) =>
                    //   setState({ ...state, password: e.target.value })
                    // }
                    className="form-control"
                    id="inputPassword"
                    onChange={(e) => {
                      handlePasswordChange(e);
                    }}
                  />
                </div>
                {errMsg.password.length > 0 && (
                  <span className="text-danger">{errMsg.password}</span>
                )}
                {(!isPasswordValid && passwordLoadingMessage)&& (
                  <h6 className="my-2" style={{ color: "red" }}>
                    Password must be at least 8 characters long
                    <br /> Password must contain a lowercase letter, an
                    uppercase letter
                    <br />
                    Password must contain number or special character.
                  </h6>
                )}
              </div>
              <div className="forgot">
                <Link to="/ForgetPassword" style={{ textDecoration: "none" }}>
                  Forgot Password?
                </Link>
              </div>

              <div className="login-btn">
                <button
                  type="submit"
                  style={{ background: "#4f9fe6" }}
                  className="btn  col-sm-12 col-md-12 col-lg-12"
                >
                  <span style={{ color: "white" }}>Login</span>
                </button>
              </div>
              <div
                className="forgot pt-3"
                style={{
                  textAlign: "center",
                }}
              >
                <Link to="/AddDelivery">
                  Don't have an account?{" "}
                  <span
                    style={{
                      color: "#4C96CB",
                    }}
                  >
                    Register
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
